import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ClosableModalTemplateComponent } from '@smooved/ui';
import { get } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';
import { MoveEffects } from '../../../move/state/move.effects';
import { AppNavigationSandbox } from '../../../navigation/sandboxes/navigation.sandbox';
import { EmojiIcon } from '../../../wizard/icon/models/emoji-icon.enum';
import { AuthenticationSandbox } from '../../sandboxes/authentication.sandbox';

function legalValidator(control: UntypedFormControl): any {
    if (control) {
        if (control.value !== true) {
            return { required: true };
        }
    }
    return null;
}

@Component({
    selector: 'app-register-dialog',
    template: `
        <app-closable-modal-template>
            <form novalidate [formGroup]="form" (ngSubmit)="onSubmit()">
                <h3 class="u-margin-bottom">Nog geen verhuisprofiel?</h3>
                <span class="u-display-block u-margin-bottom"
                    >Geef jouw e-mailadres en wij maken voor jou een verhuisprofiel. Zo worden alle ingevoerde gegevens
                    <strong>veilig voor jou bewaard</strong> en moet je deze bij een volgend bezoek
                    <strong>geen tweede keer invoeren</strong>.</span
                >
                <app-email-input [width]="'100%'" [formControlName]="'email'"></app-email-input>

                <app-gdpr class="u-display-block u-margin-bottom-double" *ngIf="showLegal$ | async" [formControlName]="'legal'"></app-gdpr>

                <div class="u-flex-row u-flex-justify-content-center">
                    <app-button type="submit" [disabled]="form.invalid">
                        <div class="u-flex-row u-flex-align-items-center">
                            <span class="u-margin-right">Maak een verhuisprofiel aan</span>
                            <app-icon [icon]="emojiIcon.Rocket"></app-icon>
                        </div>
                    </app-button>
                </div>
            </form>
        </app-closable-modal-template>
    `,
})
export class RegisterDialog implements OnInit, OnDestroy {
    @ViewChild(ClosableModalTemplateComponent, { static: true })
    public closableModalComponent: ClosableModalTemplateComponent;

    public emojiIcon = EmojiIcon;

    public form: UntypedFormGroup = this.formBuilder.group({
        email: [null, [Validators.required, Validators.email]],
        legal: [null],
    });

    public showLegal$: Observable<boolean> = this.authenticationSandbox.isRealEstateAgentOrAdmin$.pipe(
        map((isRealEstateAgentOrAdmin) => !isRealEstateAgentOrAdmin)
    );

    private subscriptions: Subscription[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private authenticationSandbox: AuthenticationSandbox,
        private navigationSandbox: AppNavigationSandbox,
        private moveEffects: MoveEffects,
        private moveSandbox: MoveSandbox
    ) {}

    public ngOnInit(): void {
        this.authenticationSandbox.isRealEstateAgentOrAdminOnce$.subscribe((isRealEstateAgentOrAdmin) => {
            if (!isRealEstateAgentOrAdmin) {
                this.form.get('legal').setValidators([legalValidator]);
            }
        });

        this.subscriptions.push(
            this.moveEffects.createMoveSuccess$.subscribe((_) => {
                this.closeDialog();
                this.navigationSandbox.goToMoveSaved().then();
            })
        );
    }

    public onSubmit(): void {
        if (this.form.valid) {
            this.moveSandbox.moveOnce$
                .pipe(
                    map((move) => {
                        return {
                            ...move,
                            ...{
                                user: {
                                    ...get(move, 'user'),
                                    email: this.emailFormControl.value,
                                },
                            },
                        };
                    })
                )
                .subscribe((move) => {
                    this.moveSandbox.createMove(move);
                });
        } else {
            this.closeDialog();
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    private closeDialog(): void {
        this.closableModalComponent.close();
    }

    private get emailFormControl(): AbstractControl {
        return this.form.get('email');
    }
}
